.reports-ctn {
  border-radius: 4px;

  &.game-analysis-report-ctn {
    .header-ctn .page-title .commission-page-title {
      display: flex;
      align-items: center;

      .info-img {
        margin-left: 6px;
      }
    }
  }

  .badge {
    text-transform: uppercase;
    font-size: 11px;
    padding: 4px;
  }
  .no-data-cell {
    text-align: center;
  }

  .admin-filters-ctn {
    position: absolute;
    display: flex;
    bottom: 10px;
    right: 30px;

    &.mob-view {
      padding-bottom: 6px;
    }

    @media screen and (max-width: 720px) {
      position: unset;

      .transaction-select-mob {
        background-color: transparent;
        border: 1px solid var(--ion-button-border);
        color: #fff;
        height: 32px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        z-index: 1;
      }
    }

    @media screen and (min-width: 720px) {
      .user-acc-balance {
        position: relative;
        top: -4px;
      }
    }

    .input-label {
      padding-bottom: 1px;

      &.input-balance {
        margin-left: 10px;
      }
    }
    .input-label,
    .date-label {
      color: var(--ion-text-light) !important;
      opacity: 0.7;
      font-size: 14px;
      line-height: 19px;

      @media screen and (max-width: 720px) {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }

  .download-btn {
    background-color: var(--ion-color-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    border-radius: 6px;
    min-width: 90px;
    color: var(--active-text-color);
    img {
      padding-right: 7px;
    }

    @media screen and (max-width: 720px) {
      min-width: 32px;
      min-height: 32px;
      max-height: 32px;
      border-radius: 4px;

      img {
        padding-right: 0;
      }
      .download-text {
        display: none;
      }
    }
    &:first-child {
      margin-right: 10px;
    }

    &:hover {
      background-color: #ff9900;
    }
  }

  .dashboard-sub-title .dashboard-date-time {
    text-align: start;
  }
  .dashboard-date-time {
    color: var(--ion-text-light);
    text-align: end;

    @media screen and (min-width: 721px) {
      width: 280px;
    }

    @media screen and (max-width: 720px) {
      font-weight: 400;
      font-size: 8.21066px;
      line-height: 10px;
    }
  }

  .dashboard-sub-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #f7f7f7;
    margin-top: 5px;

    @media screen and (max-width: 720px) {
      font-weight: 400;
      font-size: 8px;
      line-height: 12px;
    }
  }

  .header-ctn {
    background: var(--ion-header-gradient);
    height: 195px;
    padding: 12px 30px;
    position: relative;

    @media screen and (min-width: 920px) {
      height: 140px;
      &.acm-header-ctn {
        height: 206px;
      }
      padding-bottom: 18px;
    }

    .header-title-ctn {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    @media screen and (max-width: 720px) {
      height: 118px;
    }

    &.profile-header-ctn {
      height: unset;
      padding: 12px 30px;
      position: relative;
      padding-bottom: 43px;
      display: flex;

      @media screen and (max-width: 720px) {
        padding: 12px 16px;
        padding-bottom: 16px;
      }

      .page-title {
        @media screen and (max-width: 720px) {
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
        }
      }
    }

    &.running-market-header {
      height: 118px;

      @media screen and (max-width: 720px) {
        height: 72px;
      }
    }

    .hedaer-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .page-title {
      color: var(--page-titles-color);
      font-family: var(--headers-font-family);
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.01em;
      float: left;
      margin-right: auto;
      // margin-top: 20px;
    }
  }

  .select-search__input {
    background: transparent;
  }

  .filters-row {
    ion-select,
    .date-filter,
    .select-filter,
    .searchbar-input-container {
      border: 1px solid var(--filter-controls-border);
      --box-shadow: 0;
      background: transparent !important;
      color: var(--ion-text-light) !important;

      .MuiInputBase-root {
        color: var(--ion-text-light) !important;
        padding: 0 12px;
        padding-left: 12px;

        @media screen and (max-width: 720px) {
          .MuiIconButton-label {
            position: absolute;
          }
        }

        .MuiSvgIcon-root {
          color: var(--ion-text-light);
        }
      }
    }

    .MuiFormControl-marginNormal {
      margin-top: 0;
    }

    .MuiInputBase-root .MuiInputBase-input {
      padding: 0 4px;

      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
    }

    .filter-label {
      color: var(--ion-plain-text-color);
      font-size: 14px;

      @media screen and (max-width: 720px) {
        font-size: 12px;
        padding-bottom: 2px;
      }
    }

    .search-filter {
      max-height: 44px;
      max-width: 228px;

      .searchbar-input-container {
        border-radius: 3px;
        --box-shadow: 0;

        input {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }

    .MuiFormControl-root,
    ion-select {
      max-height: 44px;
      max-width: 220px;
    }
  }

  .content-ctn {
    padding: 20px 30px 0 30px;
    background-color: #f6f9ff;
    border-radius: 6px;

    .align-right {
      justify-content: flex-end;
      @media screen and (max-width: 720px) {
        .bl-btn-prev,
        .bl-btn-next {
          height: 32px;
        }
      }
    }

    .table-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableRow-root:nth-child(even) {
              background: var(--ion-card-background-contrast);
            }

            .MuiTableRow-root.back-odd-row {
              background: var(--back-odd-background-light);

              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
              }
            }

            .MuiTableRow-root.lay-odd-row {
              background: var(--lay-odd-background-light);

              .MuiTableCell-body {
                .badge {
                  border-radius: 6px;
                  text-transform: uppercase !important;
                  font-size: 11px;
                  padding: 4px;
                }
              }
              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
              }
            }
          }
        }
      }
    }
  }

  .header-ctn,
  .content-ctn {
    .points-type-toggle {
      background-color: transparent;
      border: 1.5px solid #636c89;
      display: inline-flex;
      height: 40px;
      border-radius: 4px;

      .MuiBottomNavigationAction-root {
        color: var(--ion-text-color);
        font-weight: 500;
        font-size: 16px;
        padding: 0px !important;

        &.Mui-selected {
          color: var(--ion-text-light);
          background: var(--ion-color-success);
        }

        .MuiBottomNavigationAction-label {
          font-size: 14px !important;
        }
      }
    }
  }

  .tbl-ctn {
    .MuiTableContainer-root {
      .MuiTable-root {
        .MuiTableHead-root {
          background: var(--ion-header-background) !important;

          .MuiTableCell-head {
            padding: 19px 12px 19px 12px;
            color: #fff !important;
            text-transform: capitalize;

            @media screen and (max-width: 720px) {
              padding: 11px 24px 11px 16px;
              font-weight: 600;
              font-size: 12px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }

  .tbl-ctn:not(.no-hov-style) {
    .MuiTableContainer-root {
      .MuiTable-root {
        .MuiTableBody-root {
          .MuiTableRow-root:hover {
            background-color: rgba(255, 167, 36, 0.2) !important;
          }
        }
      }
    }
  }

  .no-data-row {
    background: none;
    width: 100%;
    border-bottom: 0px !important;
    color: var(--ion-text-color-contrast);
    height: 40px;
    padding-top: 15px;
    padding-left: 15px;
    font-family: var(--ion-font-family);
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .reports-ctn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 0px !important;
  }

  .reports-ctn {
    // margin: 10px 0px !important;

    .header-ctn,
    .content-ctn {
      padding: 10px 0px;

      .points-type-toggle {
        border: 1.5px solid #636c89;
        height: 30px;
        border-radius: 4px;
        margin-top: 2px;

        .MuiBottomNavigationAction-root {
          line-height: 17px;
          min-width: 52px;

          .MuiBottomNavigationAction-label {
            font-size: 12px !important;
          }
        }
      }
    }

    .header-ctn {
      padding-left: 16px;
      padding-right: 16px;

      .page-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      .summary-card {
        ion-row:not(:last-child) {
          margin-bottom: 10px;
        }

        .summary-expand-toggle {
          text-align: center;

          .summary-expand-toggle-btn {
            color: var(--ion-text-color);
            text-transform: none;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02em;
            height: 20px;

            ion-icon {
              color: var(--ion-text-color);
              height: 18px;
            }
          }
        }
      }
    }

    .content-ctn {
      .table-ctn {
        .MuiTableCell-root {
          padding: 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          .badge {
            border-radius: 6px;
            text-transform: uppercase !important;
            font-size: 11px;
            padding: 4px;
          }
        }
      }

      .filters,
      .filters-row {
        font-size: 12px;

        ion-select,
        .MuiFormControl-marginNormal {
          height: 32px !important;
          margin-top: 0;
        }

        .MuiInputBase-root .MuiInputBase-input {
          padding: 0 12px;
          font-size: 12px;
        }

        .search-filter {
          display: none;
        }
      }
    }
  }
}
