.change-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px !important;

  .input-label {
    font-weight: bold;
  }
  .MuiDialogContent-root {
    padding: 0px !important;
  }

  @media screen and (max-width: 720px) {
    height: 90%;
  }

  .usr-input,
  .pwd-input {
    margin-top: 0;
    width: 100%;
  }

  .pwd-field {
    width: 100%;
    background-color: #eaeef3;
    max-width: unset;
  }

  .submit-form-btn {
    // margin-top: 20px !important;
    // margin-bottom: 20px;
    // width: 100%;
    // max-width: 100%;
    // height: 48px;
    // font-family: 'Jost';
    // font-weight: 500;
    // font-size: 16px;
    // line-height: 19px;
    width: -webkit-fill-available;
    width: moz-available;
    height: 60px;
    border-radius: 0px 0px 20px 20px;
    font-size: 20px;
    color: aqua;
  }

  .accept-terms-input {
    padding-top: 14px;
    max-width: 100%;

    .accept-terms-field {
      display: flex;
      flex-direction: row;
      align-items: center !important;
      max-width: unset;
      text-align: left;

      .MuiIconButton-root {
        width: fit-content;
      }
    }

    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;

      .MuiFormControlLabel-label {
        margin-left: 10px;
        font-size: 14px;
        color: var(--ion-text-color);
      }
    }

    .MuiFormHelperText-root {
      color: red;
    }

    .accept-terms-checkbox {
      color: var(--ion-text-color) !important;
      position: relative !important;
    }
  }
}

.password-input-ctn {
  display: flex;
  position: relative;
}

.password-input-field {
  flex: 8;
}

.generate-pass-btn {
  flex: 4;
  margin-left: 5px;
}

.password-refresh-icon-ctn {
  position: absolute;
  right: 10px;
  .password-refresh-icon {
    font-size: 18px !important;
    cursor: pointer;
  }
}

.fullWidth {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  padding: 0px !important;
}
.new-password-ctn {
  display: flex;
  flex-direction: row;
}
.padding-to-3 {
  padding-top: 3px;
}
.password-label-ctn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px !important;
}
.change-password-form-ctn {
  .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root {
    background: var(--dialog-content-background);
    padding: 0px !important;
  }
}
.error-msg {
  color: var(--ion-color-danger);
  margin-top: 10px;
  text-align: center;
}
// border-radius: 0px 0px 20px 20px;
.success-msg {
  color: var(--ion-color-success);
  margin-top: 10px;
  text-align: center;
}

.user-name,
.pwd-field {
  max-width: 100%;
  height: 48px;
}

.input-tooltip {
  color: var(--ion-text-color);
  font-size: 20px;
  padding-left: 4px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: -webkit-fill-available;
    width: moz-available;
  }
}
.submit-form-btn-style {
  width: -webkit-fill-available;
  width: moz-available;
  height: 60px;
  border-radius: 0px 0px 20px 20px;
  font-size: 20px;

  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: -webkit-fill-available;
    width: moz-available;
  }
}

.password-dialog-ctn.MuiDialog-root
  .MuiDialog-container
  .MuiDialog-paper
  .MuiIconButton-root {
  top: 0;
  background-color: unset !important;
  color: black !important;
}

.password-dialog-ctn {
  .MuiDialog-paper {
    border-radius: 30px;

    .MuiDialogContent-root {
      background-color: unset !important;
      padding: 0;

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
      .MuiInputBase-root .MuiInputBase-input {
        background-color: var(--input-background) !important;
        border-radius: 10px;
        border: 0;
    }
    }

    .MuiButtonBase-root {
      width: 100%;
    }
  }
}
